import React, { useState, useCallback } from 'react';
import { GoogleMap, useLoadScript, Circle, InfoWindow } from '@react-google-maps/api';
import { AlertTriangle, Activity, Info } from 'lucide-react';
import AddressAutocomplete from './AddressAutocomplete';
import SearchResult from './SearchResult';

interface RadonDataPoint {
  location: { lat: number; lng: number };
  level: number;
  city: string;
  risk: 'low' | 'medium' | 'high';
  homes?: number;
}

const radonData: RadonDataPoint[] = [
  { location: { lat: 45.4215, lng: -75.6972 }, level: 200, city: "Ottawa", risk: "medium", homes: 1250 },
  { location: { lat: 43.6532, lng: -79.3832 }, level: 150, city: "Toronto", risk: "medium", homes: 2300 },
  { location: { lat: 51.0447, lng: -114.0719 }, level: 300, city: "Calgary", risk: "high", homes: 1800 },
  { location: { lat: 49.2827, lng: -123.1207 }, level: 100, city: "Vancouver", risk: "low", homes: 950 },
  { location: { lat: 53.5461, lng: -113.4938 }, level: 250, city: "Edmonton", risk: "high", homes: 1600 },
  { location: { lat: 45.5017, lng: -73.5673 }, level: 175, city: "Montreal", risk: "medium", homes: 2100 },
  { location: { lat: 49.8951, lng: -97.1384 }, level: 225, city: "Winnipeg", risk: "high", homes: 1400 },
  { location: { lat: 44.6488, lng: -63.5752 }, level: 125, city: "Halifax", risk: "low", homes: 750 }
];

const getColor = (level: number) => {
  if (level < 100) return { color: "#22c55e", label: "Low Risk (<100 Bq/m³)" };
  if (level < 200) return { color: "#eab308", label: "Medium Risk (100-200 Bq/m³)" };
  return { color: "#ef4444", label: "High Risk (>200 Bq/m³)" };
};

const mapOptions = {
  disableDefaultUI: true,
  zoomControl: true,
  styles: [
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#e9e9e9" }, { lightness: 17 }]
    },
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [{ color: "#f5f5f5" }, { lightness: 20 }]
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [{ color: "#ffffff" }, { lightness: 17 }]
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: "#ffffff" }, { lightness: 29 }, { weight: 0.2 }]
    }
  ]
};

const RadonMap: React.FC = () => {
  const [selectedPoint, setSelectedPoint] = useState<RadonDataPoint | null>(null);
  const [searchResult, setSearchResult] = useState<any | null>(null);
  const [center, setCenter] = useState({ lat: 56.1304, lng: -106.3468 });
  const [zoom, setZoom] = useState(4);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    libraries: ["places"]
  });

  const handleAddressSelect = useCallback((address: string, lat: number, lng: number) => {
    setCenter({ lat, lng });
    setZoom(12);
    
    // Find nearest data point for risk assessment
    const nearest = radonData.reduce((prev, curr) => {
      const prevDist = Math.sqrt(
        Math.pow(prev.location.lat - lat, 2) + 
        Math.pow(prev.location.lng - lng, 2)
      );
      const currDist = Math.sqrt(
        Math.pow(curr.location.lat - lat, 2) + 
        Math.pow(curr.location.lng - lng, 2)
      );
      return prevDist < currDist ? prev : curr;
    });

    setSearchResult({
      address,
      risk: nearest.risk,
      level: nearest.level,
      city: nearest.city,
      province: "ON",
      homes: nearest.homes
    });
  }, []);

  if (loadError) {
    return <div className="text-center p-4">Error loading map</div>;
  }

  if (!isLoaded) {
    return <div className="text-center p-4">Loading map...</div>;
  }

  return (
    <div className="relative">
      <div className="absolute top-4 left-4 right-4 z-[1000]">
        <AddressAutocomplete onAddressSelect={handleAddressSelect} />
      </div>

      <GoogleMap
        mapContainerClassName="w-full h-[70vh]"
        center={center}
        zoom={zoom}
        options={mapOptions}
      >
        {radonData.map((point, index) => {
          const riskInfo = getColor(point.level);
          return (
            <Circle
              key={index}
              center={point.location}
              radius={75000}
              options={{
                fillColor: riskInfo.color,
                fillOpacity: 0.35,
                strokeColor: riskInfo.color,
                strokeOpacity: 1,
                strokeWeight: 2,
              }}
              onClick={() => setSelectedPoint(point)}
            />
          );
        })}

        {selectedPoint && (
          <InfoWindow
            position={selectedPoint.location}
            onCloseClick={() => setSelectedPoint(null)}
          >
            <div className="p-2 min-w-[200px]">
              <h3 className="font-bold text-lg mb-2">{selectedPoint.city}</h3>
              <div className="space-y-1">
                <p>Average Level: <span className="font-semibold">{selectedPoint.level} Bq/m³</span></p>
                <p>Risk Level: <span className={`font-semibold ${
                  selectedPoint.risk === "high" ? "text-red-600" :
                  selectedPoint.risk === "medium" ? "text-yellow-600" :
                  "text-green-600"
                }`}>{selectedPoint.risk.charAt(0).toUpperCase() + selectedPoint.risk.slice(1)}</span></p>
                {selectedPoint.homes && (
                  <p>Homes Tested: <span className="font-semibold">{selectedPoint.homes.toLocaleString()}</span></p>
                )}
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>

      {searchResult && (
        <SearchResult
          address={searchResult.address}
          risk={searchResult.risk}
          level={searchResult.level}
          city={searchResult.city}
          province={searchResult.province}
          homes={searchResult.homes}
          onClose={() => setSearchResult(null)}
        />
      )}
    </div>
  );
};

export default RadonMap;