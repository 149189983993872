import React from 'react';
import { Link } from 'react-router-dom';
import { RadioTower, Mail, Phone } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="col-span-1">
            <Link to="/" className="flex items-center">
              <RadioTower className="h-8 w-8 text-red-600" />
              <span className="ml-2 text-xl font-bold">RadonMap.ca</span>
            </Link>
            <p className="mt-4 text-gray-400">
              Canada's trusted source for radon information and testing
            </p>
          </div>
          
          <div>
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li><Link to="/" className="text-gray-400 hover:text-white">Home</Link></li>
              <li><Link to="/testing" className="text-gray-400 hover:text-white">Testing</Link></li>
              <li><Link to="/mitigation" className="text-gray-400 hover:text-white">Mitigation</Link></li>
              <li><Link to="/blog" className="text-gray-400 hover:text-white">Blog</Link></li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-lg font-semibold mb-4">Resources</h3>
            <ul className="space-y-2">
              <li><Link to="/testing" className="text-gray-400 hover:text-white">Testing Guide</Link></li>
              <li><Link to="/mitigation" className="text-gray-400 hover:text-white">Mitigation Options</Link></li>
              <li>
                <a 
                  href="https://www.canada.ca/en/health-canada/services/environmental-workplace-health/radiation/radon/government-canada-radon-guideline.html" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-gray-400 hover:text-white"
                >
                  Health Guidelines
                </a>
              </li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-lg font-semibold mb-4">Contact</h3>
            <ul className="space-y-2">
              <li className="flex items-center">
                <Mail className="h-5 w-5 mr-2 text-red-600" />
                <a href="mailto:info@radonmap.ca" className="text-gray-400 hover:text-white">
                  info@radonmap.ca
                </a>
              </li>
              <li className="flex items-center">
                <Phone className="h-5 w-5 mr-2 text-red-600" />
                <a href="tel:1-800-555-0123" className="text-gray-400 hover:text-white">
                  1-800-555-0123
                </a>
              </li>
              <li className="mt-4">
                <Link to="/testing" className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 inline-block">
                  Book Testing
                </Link>
              </li>
            </ul>
          </div>
        </div>
        
        <div className="mt-12 pt-8 border-t border-gray-800 text-center text-gray-400">
          <p>&copy; {new Date().getFullYear()} RadonMap.ca. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;