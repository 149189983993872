import React from 'react';
import { Link } from 'react-router-dom';
import { Calendar, Clock, ArrowRight } from 'lucide-react';

const blogPosts = [
  {
    id: 1,
    title: "Understanding Radon Levels in Canadian Homes",
    excerpt: "A comprehensive guide to radon levels across Canada, including provincial variations, testing methods, and health implications...",
    date: "2024-03-15",
    readTime: "8 min read",
    image: "https://images.unsplash.com/photo-1560518883-ce09059eeffa?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
    slug: "understanding-radon-levels-canadian-homes",
    metaDescription: "Learn about safe radon levels in Canadian homes, provincial testing requirements, and how to protect your family from radon exposure.",
    keywords: "radon levels Canada, Canadian home radon, radon testing, radon health risks, provincial radon guidelines",
    content: `
      Radon exposure is a significant concern for Canadian homeowners, with approximately 7% of homes having radon levels above the recommended guideline of 200 Bq/m³. This comprehensive guide explores radon levels across different provinces, testing methodologies, and health implications.

      ## Provincial Variations in Radon Levels

      Different regions of Canada show varying radon concentrations:
      - Prairie Provinces: Higher average levels due to uranium-rich soil
      - Coastal Regions: Generally lower levels but with significant local variations
      - Urban Centers: Mixed results depending on construction methods and geology

      ## Health Implications

      Long-term exposure to elevated radon levels increases lung cancer risk:
      - Second leading cause of lung cancer in Canada
      - Responsible for approximately 3,200 lung cancer deaths annually
      - Risk increases significantly for smokers exposed to radon

      ## Testing Methods and Frequency

      Health Canada recommends:
      - Long-term testing (3-12 months) for accurate results
      - Testing during heating season for worst-case scenarios
      - Regular retesting every 2-5 years

      ## FAQs

      Q: How often should I test my home for radon?
      A: Health Canada recommends testing every 2-5 years, or after major renovations.

      Q: What time of year is best for testing?
      A: Winter months when windows are typically closed provide the most accurate results.

      Q: How much does radon testing cost?
      A: Professional testing typically ranges from $150 to $300 for a long-term test.
    `
  },
  {
    id: 2,
    title: "Radon Mitigation: Advanced Solutions for Canadian Properties",
    excerpt: "Discover the latest radon mitigation technologies and strategies being used across Canada...",
    date: "2024-03-10",
    readTime: "10 min read",
    image: "https://images.unsplash.com/photo-1579684385127-1ef15d508118?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
    slug: "radon-mitigation-solutions-canadian-properties",
    metaDescription: "Explore effective radon mitigation solutions for Canadian properties, including costs, techniques, and professional installation guidelines.",
    keywords: "radon mitigation Canada, radon reduction systems, sub-slab depressurization, radon ventilation, Canadian building code",
    content: `[Full article content...]`
  },
  {
    id: 3,
    title: "Regional Radon Risk Analysis: A Cross-Canada Study",
    excerpt: "An in-depth analysis of radon risk patterns across different Canadian regions...",
    date: "2024-03-05",
    readTime: "12 min read",
    image: "https://images.unsplash.com/photo-1516156008625-3a9d6067fab5?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
    slug: "regional-radon-risk-analysis-canada",
    metaDescription: "Analyze radon risk patterns across Canadian regions with detailed maps, statistics, and prevention strategies for high-risk areas.",
    keywords: "Canadian radon map, radon risk areas, regional radon levels, geological radon factors, Canadian radon statistics",
    content: `[Full article content...]`
  },
  {
    id: 4,
    title: "The Impact of Modern Construction on Radon Levels",
    excerpt: "How modern building practices affect radon accumulation in Canadian homes...",
    date: "2024-02-28",
    readTime: "9 min read",
    image: "https://images.unsplash.com/photo-1503387762-592deb58ef4e?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
    slug: "modern-construction-radon-impact",
    metaDescription: "Learn how modern construction methods influence radon levels in Canadian homes and discover building techniques for radon prevention.",
    keywords: "radon building codes, modern construction radon, radon-resistant construction, building materials radon, energy efficiency radon",
    content: `[Full article content...]`
  },
  {
    id: 5,
    title: "Seasonal Variations in Radon Exposure: What Canadians Need to Know",
    excerpt: "Understanding how seasonal changes affect radon levels in Canadian homes...",
    date: "2024-02-20",
    readTime: "7 min read",
    image: "https://images.unsplash.com/photo-1523217582562-09d0def993a6?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
    slug: "seasonal-radon-variations-canada",
    metaDescription: "Understand how seasonal changes affect radon levels in Canadian homes and learn when to test for most accurate results.",
    keywords: "seasonal radon levels, winter radon testing, summer radon levels, weather impact radon, Canadian climate radon",
    content: `[Full article content...]`
  }
];

const Blog = () => {
  return (
    <div className="pt-20 pb-12 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold text-gray-900 mb-8">Latest Articles</h1>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogPosts.map((post) => (
            <article key={post.id} className="bg-white rounded-lg shadow-lg overflow-hidden transition-transform hover:scale-[1.02]">
              <Link to={`/blog/${post.slug}`}>
                <img
                  src={post.image}
                  alt={post.title}
                  className="w-full h-48 object-cover"
                />
                <div className="p-6">
                  <div className="flex items-center text-sm text-gray-500 mb-2">
                    <Calendar className="h-4 w-4 mr-2" />
                    <time>{post.date}</time>
                    <span className="mx-2">•</span>
                    <Clock className="h-4 w-4 mr-2" />
                    <span>{post.readTime}</span>
                  </div>
                  <h2 className="text-xl font-bold text-gray-900 mb-3">
                    {post.title}
                  </h2>
                  <p className="text-gray-600 mb-4">{post.excerpt}</p>
                  <div className="flex items-center text-red-600 hover:text-red-700 font-medium">
                    Read more
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </div>
                </div>
              </Link>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blog;