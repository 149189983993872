import React from 'react';
import { AlertTriangle, Info, MapPin, Home, Activity, Wind, ThermometerSun, Users, Building } from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts';

interface SearchResultProps {
  address: string;
  risk: 'low' | 'medium' | 'high';
  level?: number;
  onClose: () => void;
  city?: string;
  province?: string;
  homes?: number;
}

const SearchResult = ({ address, risk, level = 150, onClose, city, province, homes }: SearchResultProps) => {
  const getRiskColor = () => {
    switch (risk) {
      case 'high': return { bg: 'bg-red-50', border: 'border-red-200', text: 'text-red-600', gradient: ['#fee2e2', '#ef4444'] };
      case 'medium': return { bg: 'bg-yellow-50', border: 'border-yellow-200', text: 'text-yellow-600', gradient: ['#fef3c7', '#f59e0b'] };
      case 'low': return { bg: 'bg-green-50', border: 'border-green-200', text: 'text-green-600', gradient: ['#dcfce7', '#22c55e'] };
    }
  };

  const getRiskText = () => {
    switch (risk) {
      case 'high': return {
        title: 'Higher Potential Risk Area',
        message: "Based on Health Canada data, your area shows elevated radon potential. While this doesn't guarantee high levels in your home, testing is strongly recommended.",
        recommendation: "Testing is strongly recommended as your area has shown higher radon levels in previous studies."
      };
      case 'medium': return {
        title: 'Moderate Potential Risk Area',
        message: 'Your area has shown moderate radon levels in previous testing. Consider testing to understand your specific exposure level.',
        recommendation: "Testing is recommended to establish baseline radon levels in your home."
      };
      case 'low': return {
        title: 'Lower Potential Risk Area',
        message: 'While your area typically shows lower radon levels, testing is still valuable as levels can vary significantly between homes.',
        recommendation: "Consider testing as part of your regular home maintenance routine."
      };
    };
  };

  // Seasonal variation data based on typical patterns
  const monthlyData = [
    { month: 'Jan', level: level * 1.2, outdoor: level * 0.3 },
    { month: 'Feb', level: level * 1.1, outdoor: level * 0.25 },
    { month: 'Mar', level: level * 0.9, outdoor: level * 0.2 },
    { month: 'Apr', level: level * 0.8, outdoor: level * 0.2 },
    { month: 'May', level: level * 0.7, outdoor: level * 0.15 },
    { month: 'Jun', level: level * 0.6, outdoor: level * 0.15 },
    { month: 'Jul', level: level * 0.6, outdoor: level * 0.15 },
    { month: 'Aug', level: level * 0.7, outdoor: level * 0.15 },
    { month: 'Sep', level: level * 0.8, outdoor: level * 0.2 },
    { month: 'Oct', level: level * 0.9, outdoor: level * 0.2 },
    { month: 'Nov', level: level * 1.1, outdoor: level * 0.25 },
    { month: 'Dec', level: level * 1.2, outdoor: level * 0.3 },
  ];

  return (
    <div className="fixed inset-0 bg-black/20 backdrop-blur-sm z-[1000] overflow-y-auto">
      <div className="min-h-screen py-8 px-4 flex items-center justify-center">
        <div className={`w-full max-w-6xl bg-white rounded-xl shadow-2xl border-t-4 ${getRiskColor().text.replace('text', 'border')} relative`}>
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-xl"
          >
            ×
          </button>

          {/* Header Section */}
          <div className="p-8 border-b">
            <div className="flex items-start space-x-4">
              <div className="flex-shrink-0">
                {risk === 'high' ? (
                  <AlertTriangle className={`h-8 w-8 ${getRiskColor().text}`} />
                ) : (
                  <Info className={`h-8 w-8 ${getRiskColor().text}`} />
                )}
              </div>
              <div className="flex-1">
                <h2 className="text-2xl font-bold mb-2">{getRiskText().title}</h2>
                <div className="flex flex-col space-y-2">
                  <div className="flex items-center text-gray-600">
                    <MapPin className="h-5 w-5 mr-2" />
                    <p className="text-lg">{address}</p>
                  </div>
                  {city && province && (
                    <div className="flex items-center text-gray-600">
                      <Building className="h-5 w-5 mr-2" />
                      <p className="text-lg">{city}, {province}</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex-shrink-0 text-center">
                <div className={`text-4xl font-bold ${getRiskColor().text}`}>
                  {level}
                  <span className="text-base ml-1">Bq/m³</span>
                </div>
                <p className="text-sm text-gray-500">Average Level</p>
              </div>
            </div>
          </div>

          <div className="p-8 space-y-8">
            {/* Key Metrics */}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
              {[
                { icon: Activity, label: 'Risk Level', value: risk.charAt(0).toUpperCase() + risk.slice(1) },
                { icon: Users, label: 'Homes Tested', value: homes?.toLocaleString() || 'N/A' },
                { icon: Wind, label: 'Ventilation Impact', value: 'High' },
                { icon: ThermometerSun, label: 'Seasonal Variance', value: '±40%' },
              ].map((metric, index) => (
                <div key={index} className="bg-gray-50 rounded-lg p-4">
                  <div className="flex items-center space-x-3">
                    <metric.icon className={`h-6 w-6 ${getRiskColor().text}`} />
                    <div>
                      <p className="text-sm text-gray-500">{metric.label}</p>
                      <p className="text-lg font-semibold">{metric.value}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Charts Grid */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
              {/* Seasonal Variation Chart */}
              <div className="bg-white p-6 rounded-lg border">
                <h3 className="text-lg font-semibold mb-4">Seasonal Variation</h3>
                <div className="h-80">
                  <ResponsiveContainer width="100%" height="100%">
                    <AreaChart data={monthlyData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" />
                      <YAxis />
                      <Tooltip />
                      <defs>
                        <linearGradient id="colorLevel" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor={getRiskColor().gradient[1]} stopOpacity={0.8}/>
                          <stop offset="95%" stopColor={getRiskColor().gradient[0]} stopOpacity={0.2}/>
                        </linearGradient>
                      </defs>
                      <Area 
                        type="monotone" 
                        dataKey="level" 
                        stroke={getRiskColor().gradient[1]} 
                        fill="url(#colorLevel)"
                        name="Indoor Level"
                      />
                      <Area 
                        type="monotone" 
                        dataKey="outdoor" 
                        stroke="#94a3b8" 
                        fill="#f1f5f9"
                        name="Outdoor Level"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </div>

              {/* Health Impact Info */}
              <div className="bg-white p-6 rounded-lg border">
                <h3 className="text-lg font-semibold mb-4">Understanding Your Results</h3>
                <div className="space-y-4">
                  <p className="text-gray-600">{getRiskText().message}</p>
                  <div className="mt-4">
                    <h4 className="font-semibold mb-2">Health Canada Guidelines:</h4>
                    <ul className="space-y-2">
                      <li className="flex items-center">
                        <div className="w-3 h-3 rounded-full bg-green-500 mr-2"></div>
                        <span>Below 100 Bq/m³: No action required</span>
                      </li>
                      <li className="flex items-center">
                        <div className="w-3 h-3 rounded-full bg-yellow-500 mr-2"></div>
                        <span>100-200 Bq/m³: Consider mitigation</span>
                      </li>
                      <li className="flex items-center">
                        <div className="w-3 h-3 rounded-full bg-red-500 mr-2"></div>
                        <span>Above 200 Bq/m³: Mitigation recommended</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* Recommendations Section */}
            <div className="bg-gray-50 p-6 rounded-lg">
              <h3 className="text-lg font-semibold mb-4">Recommendations</h3>
              <p className="text-gray-700 mb-4">{getRiskText().recommendation}</p>
              <div className="flex flex-wrap gap-4">
                <a
                  href="/testing"
                  className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700"
                >
                  Schedule Professional Testing
                </a>
                <a
                  href="/mitigation"
                  className="inline-flex items-center px-6 py-3 border border-gray-300 text-base font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50"
                >
                  Learn About Mitigation Options
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResult;