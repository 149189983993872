import React from 'react';
import RadonMap from '../components/RadonMap';
import ReportForm from '../components/ReportForm';
import { AlertTriangle, Shield, Activity, Users, Home as HomeIcon } from 'lucide-react';

const Home = () => {
  const stats = [
    { label: "Homes Tested", value: "10,000+", icon: HomeIcon },
    { label: "Average Level", value: "126 Bq/m³", icon: Activity },
    { label: "High Risk Areas", value: "35%", icon: AlertTriangle },
    { label: "Protected Homes", value: "5,000+", icon: Shield }
  ];

  return (
    <div>
      {/* Hero Section */}
      <section className="relative bg-gradient-to-b from-gray-900 to-gray-800 text-white py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto">
            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              Understand Radon Levels in Your Area
            </h1>
            <p className="text-xl mb-8 text-gray-300">
              Access Canada's most comprehensive radon map and get personalized reports for your property
            </p>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-12">
              {stats.map((stat, index) => (
                <div key={index} className="bg-white/10 backdrop-blur-sm rounded-lg p-4">
                  <stat.icon className="h-6 w-6 text-red-500 mx-auto mb-2" />
                  <div className="text-2xl font-bold">{stat.value}</div>
                  <div className="text-sm text-gray-300">{stat.label}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        
        {/* Animated scroll indicator */}
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 animate-bounce">
          <div className="w-6 h-10 border-2 border-white rounded-full flex items-start justify-center p-1">
            <div className="w-1 h-3 bg-white rounded-full animate-scroll"></div>
          </div>
        </div>
      </section>

      {/* Map Section - Now Full Width */}
      <section className="relative">
        <RadonMap />
      </section>

      {/* Trust Indicators */}
      <section className="bg-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                title: "Health Canada Aligned",
                description: "Our data and recommendations follow Health Canada guidelines"
              },
              {
                title: "Real-Time Updates",
                description: "Continuously updated with latest testing results"
              },
              {
                title: "Expert Verified",
                description: "Data verified by certified radon professionals"
              }
            ].map((item, index) => (
              <div key={index} className="text-center">
                <Shield className="h-12 w-12 text-red-600 mx-auto mb-4" />
                <h3 className="text-lg font-semibold mb-2">{item.title}</h3>
                <p className="text-gray-600">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Banner */}
      <section className="bg-red-600 text-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold mb-4">Ready to Test Your Home?</h2>
          <p className="text-xl mb-8">
            Get professional radon testing and protect your family's health
          </p>
          <div className="flex justify-center space-x-4">
            <button className="bg-white text-red-600 px-8 py-3 rounded-lg font-semibold hover:bg-gray-100 transition">
              Schedule Testing
            </button>
            <button className="border-2 border-white px-8 py-3 rounded-lg font-semibold hover:bg-white/10 transition">
              Learn More
            </button>
          </div>
        </div>
      </section>

      {/* Report Form Section */}
      <section className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">
            Get Your Personalized Report
          </h2>
          <ReportForm />
        </div>
      </section>
    </div>
  );
};

export default Home;