import React, { useEffect, useRef, useState } from 'react';
import { Search } from 'lucide-react';
import { Loader } from '@googlemaps/js-api-loader';

interface AddressAutocompleteProps {
  onAddressSelect: (address: string, lat: number, lng: number) => void;
}

const AddressAutocomplete = ({ onAddressSelect }: AddressAutocompleteProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loader = new Loader({
      apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
      version: "weekly",
      libraries: ["places"]
    });

    loader.load().then(() => {
      if (!inputRef.current) return;

      const autocomplete = new google.maps.places.Autocomplete(inputRef.current, {
        componentRestrictions: { country: 'ca' },
        types: ['address'],
        fields: ['formatted_address', 'geometry']
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place?.geometry?.location) {
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          const address = place.formatted_address || '';
          onAddressSelect(address, lat, lng);
        }
      });

      setIsLoading(false);
    });
  }, [onAddressSelect]);

  return (
    <div className="relative w-full max-w-2xl mx-auto">
      <input
        ref={inputRef}
        type="text"
        placeholder={isLoading ? "Loading..." : "Enter your Canadian address..."}
        disabled={isLoading}
        className="w-full px-4 py-3 pl-12 rounded-lg shadow-lg border-2 
          border-gray-200 focus:border-red-500 focus:ring-2 
          focus:ring-red-500 focus:outline-none
          bg-white/90 backdrop-blur-sm"
      />
      <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
    </div>
  );
};

export default AddressAutocomplete;