import React, { useState } from 'react';
import { ClipboardCheck, CheckCircle, AlertTriangle, Clock } from 'lucide-react';
import BookingModal from '../components/BookingModal';

const Testing = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState('');

  const testingSteps = [
    {
      icon: ClipboardCheck,
      title: "Initial Assessment",
      description: "Professional evaluation of your property to determine optimal testing locations"
    },
    {
      icon: Clock,
      title: "Long-term Testing",
      description: "3-12 month testing period for most accurate results"
    },
    {
      icon: AlertTriangle,
      title: "Analysis",
      description: "Expert analysis of results and risk assessment"
    },
    {
      icon: CheckCircle,
      title: "Recommendations",
      description: "Detailed report with actionable recommendations"
    }
  ];

  const handleBooking = (service: string) => {
    setSelectedService(service);
    setIsModalOpen(true);
  };

  return (
    <div className="pt-16">
      <section className="bg-gradient-to-b from-gray-900 to-gray-800 text-white py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl md:text-5xl font-bold mb-6 text-center">Professional Radon Testing</h1>
          <p className="text-xl text-center max-w-3xl mx-auto">
            Accurate, reliable radon testing services for your peace of mind
          </p>
        </div>
      </section>

      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-3xl font-bold mb-6">Why Test for Radon?</h2>
              <div className="space-y-4">
                <p className="text-gray-600">
                  Radon is the leading cause of lung cancer among non-smokers. Testing is the only way 
                  to know if you and your family are at risk from radon gas exposure.
                </p>
                <p className="text-gray-600">
                  Our professional testing service provides accurate measurements using state-of-the-art 
                  equipment and follows strict protocols to ensure reliable results.
                </p>
              </div>
            </div>
            <div className="bg-gray-50 p-8 rounded-lg">
              <h3 className="text-2xl font-bold mb-4">Testing Process</h3>
              <div className="space-y-6">
                {testingSteps.map((step, index) => (
                  <div key={index} className="flex items-start">
                    <step.icon className="h-6 w-6 text-red-600 mt-1 flex-shrink-0" />
                    <div className="ml-4">
                      <h4 className="font-semibold">{step.title}</h4>
                      <p className="text-gray-600">{step.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold mb-12 text-center">Testing Options</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                title: "Short-Term Test",
                duration: "2-7 days",
                price: "$149",
                features: [
                  "Quick initial assessment",
                  "Digital monitoring",
                  "Basic report",
                  "Email support"
                ]
              },
              {
                title: "Long-Term Test",
                duration: "3-12 months",
                price: "$299",
                features: [
                  "Most accurate results",
                  "Continuous monitoring",
                  "Detailed analysis",
                  "Priority support"
                ]
              },
              {
                title: "Professional Package",
                duration: "Custom Solution",
                price: "Custom Quote",
                features: [
                  "Comprehensive testing",
                  "Multiple test locations",
                  "Mitigation consultation",
                  "Full remediation plan",
                  "24/7 expert support"
                ]
              }
            ].map((plan, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-sm">
                <h3 className="text-xl font-bold mb-2">{plan.title}</h3>
                <p className="text-gray-600 mb-4">Duration: {plan.duration}</p>
                <p className="text-3xl font-bold text-red-600 mb-6">{plan.price}</p>
                <ul className="space-y-2">
                  {plan.features.map((feature, fIndex) => (
                    <li key={fIndex} className="flex items-center">
                      <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
                      <span className="text-gray-600">{feature}</span>
                    </li>
                  ))}
                </ul>
                <button 
                  onClick={() => handleBooking(plan.title)}
                  className="w-full mt-6 bg-red-600 text-white py-2 px-4 rounded-md hover:bg-red-700 transition duration-200"
                >
                  {plan.title === "Professional Package" ? "Request Quote" : "Book Now"}
                </button>
              </div>
            ))}
          </div>
        </div>
      </section>

      <BookingModal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        service={selectedService}
      />
    </div>
  );
};

export default Testing;