import React from 'react';
import { Shield, Home, Wrench, ThumbsUp } from 'lucide-react';

const Mitigation = () => {
  const mitigationMethods = [
    {
      title: "Sub-slab Depressurization",
      description: "The most effective method that creates a vacuum beneath the foundation to prevent radon entry",
      image: "https://images.unsplash.com/photo-1541888946425-d81bb19240f5?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
    },
    {
      title: "Soil Pressurization",
      description: "Reverses the air pressure differences that draw radon into the home",
      image: "https://images.unsplash.com/photo-1503387762-592deb58ef4e?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
    },
    {
      title: "Ventilation Improvement",
      description: "Increases air exchange rate and reduces radon concentration",
      image: "https://images.unsplash.com/photo-1516156008625-3a9d6067fab5?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
    }
  ];

  return (
    <div className="pt-16">
      <section className="bg-gradient-to-b from-gray-900 to-gray-800 text-white py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl md:text-5xl font-bold mb-6 text-center">Radon Mitigation Solutions</h1>
          <p className="text-xl text-center max-w-3xl mx-auto">
            Professional radon reduction systems and services to protect your home and family
          </p>
        </div>
      </section>

      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-3xl font-bold mb-6">Expert Mitigation Services</h2>
              <div className="space-y-4">
                <p className="text-gray-600">
                  Our certified radon mitigation experts design and install custom solutions to 
                  effectively reduce radon levels in your home. We use proven techniques and 
                  high-quality materials to ensure long-lasting protection.
                </p>
                <div className="grid grid-cols-2 gap-4 mt-8">
                  {[
                    { icon: Shield, text: "Guaranteed Results" },
                    { icon: Home, text: "Custom Solutions" },
                    { icon: Wrench, text: "Professional Installation" },
                    { icon: ThumbsUp, text: "Lifetime Support" }
                  ].map((item, index) => (
                    <div key={index} className="flex items-center">
                      <item.icon className="h-5 w-5 text-red-600 mr-2" />
                      <span className="text-gray-700">{item.text}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="bg-gray-50 p-8 rounded-lg">
              <h3 className="text-2xl font-bold mb-6">Our Process</h3>
              <div className="space-y-6">
                {[
                  "Initial consultation and site assessment",
                  "Custom system design based on your home's needs",
                  "Professional installation by certified technicians",
                  "Post-installation testing and verification",
                  "Ongoing support and maintenance"
                ].map((step, index) => (
                  <div key={index} className="flex items-start">
                    <span className="flex-shrink-0 h-6 w-6 rounded-full bg-red-600 text-white flex items-center justify-center text-sm">
                      {index + 1}
                    </span>
                    <p className="ml-4 text-gray-600">{step}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold mb-12 text-center">Mitigation Methods</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {mitigationMethods.map((method, index) => (
              <div key={index} className="bg-white rounded-lg overflow-hidden shadow-sm">
                <img
                  src={method.image}
                  alt={method.title}
                  className="w-full h-48 object-cover"
                />
                <div className="p-6">
                  <h3 className="text-xl font-bold mb-2">{method.title}</h3>
                  <p className="text-gray-600">{method.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold mb-8">Ready to Get Started?</h2>
          <p className="text-gray-600 mb-8 max-w-2xl mx-auto">
            Contact us today for a free consultation and quote. Our experts will help you choose 
            the best mitigation solution for your home.
          </p>
          <button className="bg-red-600 text-white py-3 px-8 rounded-md hover:bg-red-700 transition duration-200">
            Request a Quote
          </button>
        </div>
      </section>
    </div>
  );
};

export default Mitigation;