import React from 'react';
import { AlertTriangle, Shield, Activity, ThermometerSun } from 'lucide-react';

const About = () => {
  return (
    <div className="pt-16">
      <section className="bg-gradient-to-b from-gray-900 to-gray-800 text-white py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl md:text-5xl font-bold mb-6 text-center">Understanding Radon</h1>
          <p className="text-xl text-center max-w-3xl mx-auto">
            Learn about radon gas, its health impacts, and why testing is crucial for Canadian homes
          </p>
        </div>
      </section>

      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 gap-12">
            <div>
              <h2 className="text-3xl font-bold mb-6">What is Radon?</h2>
              <p className="text-gray-600 mb-4">
                Radon is a naturally occurring radioactive gas that's invisible, odorless, and tasteless. 
                It's produced by the breakdown of uranium in soil, rock, and water, and can enter buildings 
                through foundation cracks and other openings.
              </p>
              <p className="text-gray-600">
                In Canada, radon is the second leading cause of lung cancer after smoking, making it a 
                significant public health concern that requires attention and action.
              </p>
            </div>
            <div className="grid grid-cols-2 gap-6">
              <div className="bg-gray-50 p-6 rounded-lg">
                <AlertTriangle className="h-8 w-8 text-red-600 mb-4" />
                <h3 className="font-bold mb-2">Health Risk</h3>
                <p className="text-sm text-gray-600">Leading cause of lung cancer in non-smokers</p>
              </div>
              <div className="bg-gray-50 p-6 rounded-lg">
                <Shield className="h-8 w-8 text-red-600 mb-4" />
                <h3 className="font-bold mb-2">Prevention</h3>
                <p className="text-sm text-gray-600">Testing and mitigation can protect your health</p>
              </div>
              <div className="bg-gray-50 p-6 rounded-lg">
                <Activity className="h-8 w-8 text-red-600 mb-4" />
                <h3 className="font-bold mb-2">Monitoring</h3>
                <p className="text-sm text-gray-600">Regular testing ensures safety</p>
              </div>
              <div className="bg-gray-50 p-6 rounded-lg">
                <ThermometerSun className="h-8 w-8 text-red-600 mb-4" />
                <h3 className="font-bold mb-2">Seasonal</h3>
                <p className="text-sm text-gray-600">Levels can vary by season</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold mb-12 text-center">Common Questions About Radon</h2>
          <div className="grid md:grid-cols-2 gap-8">
            {[
              {
                q: "How does radon enter homes?",
                a: "Radon enters through foundation cracks, construction joints, gaps around pipes, and other openings."
              },
              {
                q: "What are safe radon levels?",
                a: "Health Canada recommends remedial measures when radon levels exceed 200 Bq/m³ in indoor air."
              },
              {
                q: "How long does testing take?",
                a: "Long-term testing (3-12 months) provides the most accurate results for making informed decisions."
              },
              {
                q: "Can radon be removed?",
                a: "Yes, professional mitigation systems can effectively reduce radon levels in your home."
              }
            ].map((item, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-sm">
                <h3 className="font-bold text-lg mb-2">{item.q}</h3>
                <p className="text-gray-600">{item.a}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;