import React, { useState } from 'react';
import { ClipboardCheck } from 'lucide-react';

const ReportForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
    postalCode: '',
    interests: {
      testing: false,
      consultation: false,
      mitigation: false,
      newsletter: false,
    },
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission - in production, this would connect to a backend
    console.log('Form submitted:', formData);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg max-w-2xl mx-auto">
      <div className="flex items-center mb-6">
        <ClipboardCheck className="h-8 w-8 text-red-600 mr-3" />
        <h2 className="text-2xl font-bold text-gray-800">Get Your Personalized Radon Report</h2>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500"
              value={formData.name}
              onChange={(e) => setFormData({...formData, name: e.target.value})}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500"
              value={formData.email}
              onChange={(e) => setFormData({...formData, email: e.target.value})}
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Address</label>
          <input
            type="text"
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500"
            value={formData.address}
            onChange={(e) => setFormData({...formData, address: e.target.value})}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Postal Code</label>
          <input
            type="text"
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500"
            value={formData.postalCode}
            onChange={(e) => setFormData({...formData, postalCode: e.target.value})}
          />
        </div>

        <div className="space-y-2">
          <p className="font-medium text-gray-700">I'm interested in:</p>
          {Object.entries({
            testing: 'Professional Radon Testing',
            consultation: 'Expert Consultation',
            mitigation: 'Radon Mitigation Solutions',
            newsletter: 'Receiving Updates & Newsletter',
          }).map(([key, label]) => (
            <div key={key} className="flex items-center">
              <input
                type="checkbox"
                id={key}
                className="h-4 w-4 text-red-600 focus:ring-red-500 border-gray-300 rounded"
                checked={formData.interests[key as keyof typeof formData.interests]}
                onChange={(e) => setFormData({
                  ...formData,
                  interests: {
                    ...formData.interests,
                    [key]: e.target.checked,
                  },
                })}
              />
              <label htmlFor={key} className="ml-2 text-sm text-gray-700">{label}</label>
            </div>
          ))}
        </div>

        <button
          type="submit"
          className="w-full bg-red-600 text-white py-2 px-4 rounded-md hover:bg-red-700 transition duration-200"
        >
          Get Your Report
        </button>
      </form>
    </div>
  );
};

export default ReportForm;